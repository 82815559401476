import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModel } from '../../redux/slices/modelSlice.js';

const ModelSelector = () => {
    const selectedModel = useSelector(state => state.model.selectedModel);
    const dispatch = useDispatch();

    const handleChange = event => {
        dispatch(setModel(event.target.value));
    };

    return (
        <div className="flex items-center">
            <label htmlFor="model-select" className="sr-only">
                Select Model
            </label>
            <select
                id="model-select"
                value={selectedModel}
                onChange={handleChange}
                className="p-1 border border-gray-300 rounded-md text-sm bg-white focus:outline-none focus:ring focus:ring-blue-200"
            >
                <option value="gpt-4o-mini">gpt-4o-mini</option>
                <option value="gpt-4o">
                    gpt-4o
                </option>
            </select>
        </div>
    );
};

export default ModelSelector;