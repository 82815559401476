import React, { useState } from 'react';

const BETA_PASSWORD = process.env.REACT_APP_BETA_PASSWORD;

const Login = () => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isOpenAITermsChecked, setIsOpenAITermsChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = () => {
    if (!isTermsChecked || !isOpenAITermsChecked) {
      setErrorMessage('You must agree to all terms to use this application.');
      return;
    }

    setErrorMessage('');

    const backendUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://chatserver.tarn.co/auth/salesforce'
        : 'http://localhost:5002/auth/salesforce';

    window.location.href = backendUrl;
  };

  const handlePasswordChange = (e) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);

    // Compare against the hardcoded beta password
    if (inputPassword === BETA_PASSWORD) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center bg-[#f9f9f9]">
      <div className="sm:mx-auto pr-2 sm:w-full sm:max-w-md">
        <img
          alt="tarn.co"
          src="/assets/Chat_Logo_01.png"
          className="mx-auto h-16 w-auto"
        />
      </div>

      <div className="mx-auto w-full">
        <div className="px-6 py-12 sm:rounded-lg sm:px-12">
          <div className="space-y-6 text-center">
            <div className="flex flex-col items-center space-y-2">
              <label htmlFor="betaPassword" className="text-sm text-gray-700">
                tarnChat is in private beta
              </label>
              <input
                type="password"
                id="betaPassword"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Private Beta Password"
                className="rounded-md border-gray-300 focus:ring-indigo-600 focus:border-indigo-600 text-gray-900 sm:text-sm p-2 w-full max-w-xs"
              />
            </div>

            <div className="flex justify-center">
              <div className="rounded-lg border border-gray-300 p-4 px-8 flex flex-col items-start space-y-4">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isTermsChecked}
                    onChange={(e) => setIsTermsChecked(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="termsCheckbox" className="text-gray-900 text-sm">
                    I agree to the{' '}
                    <a
                      href="https://tarn.co/terms-and-conditions"
                      target="_blank"
                      className="text-indigo-600 underline"
                    >
                      Terms & Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://tarn.co/data-policy"
                      target="_blank"
                      className="text-indigo-600 underline"
                    >
                      Data Privacy Policy
                    </a>
                  </label>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="openAITermsCheckbox"
                    checked={isOpenAITermsChecked}
                    onChange={(e) => setIsOpenAITermsChecked(e.target.checked)}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="openAITermsCheckbox" className="text-gray-900 text-sm">
                    I agree to{' '}
                    <a
                      href="https://openai.com/policies/row-terms-of-use/"
                      target="_blank"
                      className="text-indigo-600 underline"
                    >
                      OpenAI's Terms of Use
                    </a>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleLogin}
                disabled={!isPasswordValid}
                className={`flex items-center justify-center gap-3 rounded-md px-5 py-3 text-sm font-semibold text-gray-900 shadow-sm w-auto ${
                  isPasswordValid
                    ? 'bg-white hover:bg-gray-50 focus-visible:outline-indigo-600'
                    : 'bg-gray-200 cursor-not-allowed'
                }`}
                style={{ maxWidth: '300px' }}
              >
                <img
                  src="/assets/Salesforce_Corporate_Logo_RGB.png"
                  alt="Salesforce Logo"
                  className="h-5 w-auto ml-2"
                />
                <span>Login with Salesforce</span>
              </button>
            </div>

            {errorMessage && (
              <p className="mt-6 text-sm text-red-600 text-center">
                {errorMessage}
              </p>
            )}
          </div>
        </div>
        <div className="absolute bottom-4 inset-x-0 text-center text-sm text-gray-500">
          What is tarn?{' '}
          <a
            href="https://tarn.co/"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 ml-2"
          >
            visit tarn.co
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;