import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addUserMessage } from '../../../redux/slices/chatSlice.js';
import { setGenerating } from '../../../redux/slices/generatingSlice.js';
import { ArrowUpCircleIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import { useSocket } from '../../../socket/socketContext.js';
import { v4 as uuidv4 } from 'uuid';

const ChatInput = () => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const socket = useSocket();
  const isGenerating = useSelector((state) => state.generating.isGenerating);
  const selectedModel = useSelector((state) => state.model.selectedModel);

  const handleSendMessage = () => {
    if (!inputValue.trim() || !socket) return;

    // Set isGenerating to true on the front end
    dispatch(setGenerating(true));

    const newMessage = {
      messageId: uuidv4(),
      sender: 'user',
      type: 'text',
      timestamp: new Date().toISOString(),
      content: inputValue.trim(),
      model: selectedModel,
    };

    dispatch(addUserMessage(newMessage));

    socket.emit('userMessage', newMessage, (response) => {
      if (response?.error) {
        console.error('Failed to send message:', response.error);
      } else {
        console.log('Message sent successfully:', response?.response);
      }
    });

    setInputValue('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="relative">
      <div className="relative flex items-center p-3">
        <textarea
          className="flex-grow p-3 pr-16 h-24 rounded-lg resize-none outline-none border border-gray-300 bg-gray-200 font-sans text-sm text-gray-900 placeholder-gray-500"
          placeholder="Message tarnChat"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isGenerating}
        />
        <button
          onClick={handleSendMessage}
          className={`absolute right-4 bottom-4 ${isGenerating ? 'text-gray-800 hover:text-gray-900' : 'text-indigo-500 hover:text-indigo-700'
            }`}
        >
          {isGenerating ? (
            <StopCircleIcon className="h-10 w-10" />
          ) : (
            <ArrowUpCircleIcon className="h-10 w-10" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ChatInput;