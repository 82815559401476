export const messageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        type: 'spring',
        stiffness: 100,
        damping: 12,
      },
    }),
    exit: {
      opacity: 0,
      y: -50,
      transition: { duration: 0.3 },
    },
  };