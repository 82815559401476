// checkAuth.js
import axiosInstance from './axiosInstance.js';

const checkAuth = async () => {
  try {
    console.log('Checking authentication');
    const response = await axiosInstance.get('/auth/check-auth');
    return { isAuthenticated: response.data.isAuthenticated, error: null };
  } catch (error) {
    console.error('Authentication check failed:', error);
    return { isAuthenticated: false, error };
  }
};

export default checkAuth;