import { addSystemMessage, setChatSession, updateMessageById } from '../redux/slices/chatSlice';
import { setGeneratingWithTimeout } from '../redux/slices/generatingSlice';
import { setConnected } from '../redux/slices/websocketSlice';
import { setChatObjects } from '../redux/slices/chatObjectsSlice';

function setupSocketListeners(socket, store) {
  socket.on('connect', () => {
    console.log('Connected to WebSocket server:', socket.id);
    store.dispatch(setConnected(true));
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from WebSocket server');
    store.dispatch(setConnected(false));
  });

  socket.on('error', (error) => {
    console.error('WebSocket error:', error);
    store.dispatch(setConnected(false));
  });

  socket.on('addSystemMessage', (messagePayload) => {
    console.log('Adding system message:', messagePayload);
    store.dispatch(addSystemMessage(messagePayload));
  });

  socket.on('sessionData', (session) => {
    console.log('Received session data:', session);
    store.dispatch(setChatSession(session));
  });

  socket.on('updateMessage', ({ messageId, updates }) => {
    console.log(`Updating message with ID: ${messageId}`, updates);
    store.dispatch(updateMessageById({ messageId, updates }));
  });

  socket.on('updateChatObjects', (chatObjects) => {
    console.log('Updating chatObjects:', chatObjects);
    store.dispatch(setChatObjects(chatObjects));
  });

  socket.on('changeGenerating', (isGenerating) => {
    console.log(`Change generating state to: ${isGenerating}`);
      store.dispatch(setGeneratingWithTimeout(isGenerating));
  });
}

export default setupSocketListeners;