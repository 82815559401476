// src/components/ChatContainer.jsx
import React from 'react';
import MessageThread from './thread/MessageThread.jsx';
import ChatInput from './input/ChatInput.jsx';

const ChatContainer = () => {
  return (
    <div className="w-full h-full flex flex-col bg-[#F3F3F3]">
      {/* The main chat thread area (scrollable) */}
      <div className="flex-1 h-full overflow-y-auto">
        <MessageThread />
      </div>

      {/* The input fixed at the bottom */}
      <div className="">
        <ChatInput />
      </div>
    </div>
  );
};

export default ChatContainer;