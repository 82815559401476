import React from 'react';
import { useSelector } from 'react-redux';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import HeroIcon from './utils/HeroIcon.jsx';
import { formatTimeString, formatCredits, getHeroIconName, handleCopyPlainText } from './utils/recordCardUtils.js';
import RecordCardFields from './RecordCardFields.jsx'; // Import your new component

const RecordCard = ({ content, timestamp, model, credits }) => {
    const { attributes, relatedRecords } = content;
    const chatObjects = useSelector((state) => state.chatObjects.chatObjects);

    const timeString = formatTimeString(timestamp);
    const formattedCredits = formatCredits(credits);
    const headerHeroIconName = getHeroIconName(chatObjects, attributes?.type);

    return (
        <div className="w-full group">
            <div className="max-w-screen-lg mx-auto mb-8 rounded-lg bg-gray-100 border border-gray-300">
                {/* Header with HeroIcon and Object Name */}
                <div className="bg-[#EBEBEB] text-gray-800 font-semibold p-3 rounded-t-lg flex items-center space-x-2">
                    {headerHeroIconName && (
                        <HeroIcon
                            name={headerHeroIconName}
                            size={6}
                            className="text-indigo-500"
                        />
                    )}
                    <span>{attributes?.type}</span>
                </div>

                {/* Main Record Fields */}
                <div className="p-4 bg-white">
                    <RecordCardFields record={content} />
                </div>

                {/* Related Records */}
                {relatedRecords?.length > 0 && (
                    <div className="bg-gray-100 border-t border-gray-300 p-4">
                        <div className="font-semibold text-gray-800 mb-2">
                            Related Records:
                        </div>
                        {relatedRecords.map((record, index) => {
                            const heroIconName = getHeroIconName(chatObjects, record.attributes?.type);
                            return (
                                <div
                                    key={index}
                                    className="mb-4 p-3 bg-white rounded-lg shadow-sm border border-gray-200"
                                >
                                    <div className="flex items-center space-x-2 text-sm text-gray-600 mb-2">
                                        {heroIconName && (
                                            <HeroIcon
                                                name={heroIconName}
                                                size={6}
                                                className="text-gray-600"
                                            />
                                        )}
                                        <strong className="mt-1">
                                            {record.attributes?.type}
                                        </strong>
                                    </div>
                                    <RecordCardFields record={record} />
                                </div>
                            );
                        })}
                    </div>
                )}

                {/* Metadata Section */}
                <div className="flex items-center space-x-2 text-xs text-gray-500 mt-2 ml-5 opacity-0 group-hover:opacity-100 transition-opacity">
                    {formattedCredits && (
                        <span>
                            <strong>Credits:</strong> {formattedCredits}
                        </span>
                    )}
                    <span>{timeString}</span>
                    <span>
                        <span className="font-bold">{model}</span>
                    </span>
                    <button
                        onClick={() => handleCopyPlainText(content)}
                        className="inline-flex items-center justify-center"
                    >
                        <DocumentDuplicateIcon className="h-4 w-4 hover:text-gray-700" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RecordCard;