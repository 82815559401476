import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedModel: 'gpt-4o-mini', // Default value
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModel: (state, action) => {
      state.selectedModel = action.payload; // Update the selected model
    },
  },
});

export const { setModel } = modelSlice.actions;

export default modelSlice.reducer;