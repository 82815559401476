import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatObjects: [], // Array to store chatObjects
};

const chatObjectsSlice = createSlice({
  name: 'chatObjects',
  initialState,
  reducers: {
    setChatObjects(state, action) {
      // Replace the current chatObjects with the new array from the payload
      state.chatObjects = action.payload;
    },
  },
});

export const { setChatObjects } = chatObjectsSlice.actions;

export default chatObjectsSlice.reducer;