import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addUserMessage } from '../../../redux/slices/chatSlice.js';
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid';
import { useSocket } from '../../../socket/socketContext.js';
import { v4 as uuidv4 } from 'uuid';
import { mirage } from 'ldrs';

// Register the mirage animation
mirage.register();

const ChatInput = () => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const socket = useSocket(); // Access the socket from the SocketProvider
  const isGenerating = useSelector((state) => state.generating.isGenerating); // Get generating state

  // Retrieve the current model from Redux
  const selectedModel = useSelector((state) => state.model.selectedModel);

  const handleSendMessage = () => {
    if (!inputValue.trim() || !socket) return;

    const newMessage = {
      messageId: uuidv4(),
      sender: 'user',
      type: 'text',
      timestamp: new Date().toISOString(),
      content: inputValue.trim(),
      model: selectedModel,
    };

    dispatch(addUserMessage(newMessage));

    socket.emit('userMessage', newMessage, (response) => {
      if (response?.error) {
        console.error('Failed to send message:', response.error);
      } else {
        console.log('Message sent successfully:', response?.response);
      }
    });

    setInputValue('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="relative">

      {/* Mirage animation and icon */}
      {isGenerating && (
        <div className="flex items-center space-x-2 ml-4 mb-3">
          <img
            src="assets/tarn_Icon_Purple.png"
            alt="Generating Icon"
            className="h-6 w-6 mr-4"
          />
          <l-mirage size="60" speed="2.5" color="#A8A0E0"></l-mirage>
        </div>
      )}

      {/* Chat input */}
      <div className="relative flex items-center p-3">
        <textarea
          className="flex-grow p-3 pr-16 h-24 rounded-lg resize-none outline-none border border-gray-300 bg-gray-200 font-sans text-sm text-gray-800"
          placeholder="Message tarnChat"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <button
          onClick={handleSendMessage}
          className="absolute right-4 bottom-4 text-indigo-500 hover:text-indigo-700"
        >
          <ArrowUpCircleIcon className="h-10 w-10" />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;