import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatSession: {
    _id: null,
    userId: null,
    orgId: null,
    messages: [],
    createdAt: null,
    updatedAt: null,
  },
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatSession: (state, action) => {
      state.chatSession = action.payload;
    },
    addUserMessage: (state, action) => {
      const newMessage = action.payload;
      state.chatSession.messages.push(newMessage);
      state.chatSession.updatedAt = new Date().toISOString();
    },
    addSystemMessage: (state, action) => {
      const newMessage = action.payload;
      if (!newMessage.sender) {
        newMessage.sender = 'system';
      }
      state.chatSession.messages.push(newMessage);
      state.chatSession.updatedAt = new Date().toISOString();
    },
    updateMessageById: (state, action) => {
      const { messageId, updates } = action.payload;
      const message = state.chatSession.messages.find((msg) => msg.messageId === messageId);
      if (message) {
        Object.assign(message, updates);
        state.chatSession.updatedAt = new Date().toISOString();
      }
    },
  },
});

export const {
  setChatSession,
  addUserMessage,
  addSystemMessage,
  updateMessageById,
} = chatSlice.actions;

export default chatSlice.reducer;