import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGenerating: false,
};

const generatingSlice = createSlice({
  name: 'generating',
  initialState,
  reducers: {
    setGenerating: (state, action) => {
      state.isGenerating = action.payload;
    },
  },
});

export const { setGenerating } = generatingSlice.actions;

export const setGeneratingWithTimeout = (value) => (dispatch) => {
  dispatch(setGenerating(value));
  if (value === true) {
    setTimeout(() => {
      dispatch(setGenerating(false));
    }, 10000); // Revert to false after 10 seconds
  }
};

export default generatingSlice.reducer;