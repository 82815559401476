import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

const SystemTextCard = ({ content, timestamp, model, credits }) => {
    const timeString = new Date(timestamp)
        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        .replace(/^0/, '');

    // Extract credits value, supporting both Decimal128 and plain numbers
    const formattedCredits = credits
        ? parseFloat(credits.$numberDecimal || credits).toFixed(4)
        : null;

    // Function to remove formatting tags for plain text
    const stripFormatting = (text) =>
        text
            .replace(/<c>(.*?)<\/c>/g, '$1') // Remove <c> tags
            .replace(/\*\*(.*?)\*\*/g, '$1') // Remove ** for bold
            .replace(/_(.*?)_/g, '$1'); // Remove _ for italics

    // Function to parse the content with bold, italics, and copyable text
    const parseContent = (text) => {
        const parts = text.split(/(<c>.*?<\/c>|\*\*.*?\*\*|_.*?_)/g); // Split by formatting rules

        return parts.map((part, index) => {
            if (part.startsWith('<c>') && part.endsWith('</c>')) {
                const copyableText = part.slice(3, -4); // Remove <c> and </c>
                return (
                    <span
                        key={index}
                        className="cursor-pointer text-indigo-600 hover:underline"
                        onClick={() =>
                            navigator.clipboard.writeText(copyableText).then(() =>
                                console.log('Text copied:', copyableText)
                            )
                        }
                    >
                        {copyableText}
                        <DocumentDuplicateIcon className="h-4 w-4 inline-block ml-1 align-middle" />
                    </span>
                );
            }
            if (part.startsWith('**') && part.endsWith('**')) {
                const boldText = part.slice(2, -2); // Remove **
                return (
                    <strong key={index} className="font-bold">
                        {boldText}
                    </strong>
                );
            }
            if (part.startsWith('_') && part.endsWith('_')) {
                const italicText = part.slice(1, -1); // Remove _
                return (
                    <em key={index} className="italic">
                        {italicText}
                    </em>
                );
            }
            return part; // Return unformatted text
        });
    };

    // Handle copying the plain text of the entire message
    const handleCopyPlainText = () => {
        const plainText = stripFormatting(content);
        navigator.clipboard
            .writeText(plainText)
            .then(() => console.log('Entire message copied:', plainText))
            .catch((err) => console.error('Failed to copy message:', err));
    };

    return (
        <div className="flex items-start justify-start w-full my-2 group">
            <img
                src="/assets/Icon_Black.png"
                alt="System Icon"
                className="h-6 w-6 mt-2 mr-2"
            />
            <div className="relative w-full md:w-auto">
                {/* System message bubble */}
                <div className="px-3 pt-3 pb-1 bg-gray-100 text-left rounded-lg">
                    <div className="text-sm text-gray-800 whitespace-pre-wrap">
                        {parseContent(content)}
                    </div>
                </div>

                {/* Metadata */}
                <div className="flex items-center space-x-2 text-xs text-gray-500 mt-1 ml-5 opacity-0 group-hover:opacity-100 transition-opacity">
                    {/* Conditionally render credits */}
                    {formattedCredits && (
                        <span>
                            <strong>Credits:</strong> {formattedCredits}
                        </span>
                    )}
                    <span>
                        <span className="font-bold">{model}</span>
                    </span>
                    <span>{timeString}</span>

                    <button
                        onClick={handleCopyPlainText}
                        className="inline-flex items-center justify-center"
                    >
                        <DocumentDuplicateIcon className="h-4 w-4 hover:text-gray-700" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SystemTextCard;