import React from 'react';
import { useSelector } from 'react-redux';

const RecordCardFields = ({ record }) => {
  const chatObjects = useSelector((state) => state.chatObjects.chatObjects);

  if (!record || !record.attributes?.type) {
    return <div className="text-sm text-gray-500">No record data</div>;
  }

  const objectType = record.attributes.type;
  const matchingChatObject = chatObjects.find((obj) => obj.name === objectType);

  if (!matchingChatObject) {
    return (
      <div className="text-sm text-gray-600">
        {Object.entries(record)
          .filter(([key]) => key !== 'attributes' && key !== 'Id')
          .map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value || 'N/A'}
            </div>
          ))}
      </div>
    );
  }

  const { layout = [], fields = [] } = matchingChatObject;
  const sortedLayout = [...layout].sort((a, b) => a.position - b.position);
  const allFieldKeys = Object.keys(record).filter((k) => k !== 'attributes' && k !== 'Id');
  const layoutFieldNames = sortedLayout.map((item) => item.name);

  const layoutFields = sortedLayout.map((layoutItem) => {
    const fieldName = layoutItem.name;
    const fieldValue = record[fieldName] ?? 'N/A';
    return {
      name: fieldName,
      label: layoutItem.label,
      value: fieldValue,
    };
  });

  const leftoverKeys = allFieldKeys.filter((key) => !layoutFieldNames.includes(key));

  const leftoverFields = leftoverKeys.map((key) => {
    const matchingFieldDef = fields.find((f) => f.name === key);
    const label = matchingFieldDef?.label || key;
    const value = record[key] ?? 'N/A';
    return {
      name: key,
      label,
      value,
    };
  });

  const allDisplayFields = [...layoutFields, ...leftoverFields];
  const validDisplayFields = allDisplayFields.filter((field) =>
    fields.some((f) => f.name === field.name)
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {validDisplayFields.map((field, index) => {
        const displayValue =
          field.value && typeof field.value === 'object'
            ? JSON.stringify(field.value)
            : field.value || 'N/A';

        return (
          <div key={index} className="text-sm text-gray-600">
            <strong>{field.label}:</strong> {displayValue}
          </div>
        );
      })}
    </div>
  );
};

export default RecordCardFields;