import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './slices/chatSlice.js';
import modelReducer from './slices/modelSlice.js';
import darkModeReducer from './slices/darkModeSlice.js';
import generatingReducer from './slices/generatingSlice.js';
import websocketReducer from './slices/websocketSlice.js';
import chatObjectsReducer from './slices/chatObjectsSlice.js'; // Import the chatObjects slice

const store = configureStore({
  reducer: {
    chat: chatReducer,
    model: modelReducer,
    darkMode: darkModeReducer,
    generating: generatingReducer,
    websocket: websocketReducer,
    chatObjects: chatObjectsReducer, // Add the chatObjects slice
  },
});

export default store;