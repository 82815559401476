import React, { useEffect, useState } from 'react';
import checkAuth from './checkAuth';
import { SocketProvider } from './socket/socketContext.js';
import { Provider } from 'react-redux';
import store from './redux/store';
import Login from './components/Login.jsx';
import ChatContainer from './components/container/ChatContainer.jsx';
import Header from './components/header/Header.jsx';

function useDynamicViewportHeight() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(window.innerHeight); // Dynamically set to the visible height
    };

    window.addEventListener('resize', updateViewportHeight);
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  return viewportHeight;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const viewportHeight = useDynamicViewportHeight(); // Custom hook for dynamic height

  const socketUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5002';

  useEffect(() => {
    const authenticate = async () => {
      const { isAuthenticated } = await checkAuth();
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };

    authenticate();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Login onLoginSuccess={() => setIsAuthenticated(true)} />;
  }

  return (
    <Provider store={store}>
      <SocketProvider url={socketUrl}>
        <div
          className="flex flex-col items-center"
          style={{
            height: viewportHeight, // Dynamically set height
            maxWidth: '700px',
            margin: '0 auto', // Center horizontally
            width: '100%', // Ensure it doesn't exceed the screen width
          }}
        >
          <Header />
          <div className="flex-1 overflow-hidden mt-[64px] w-full">
            {/* Ensure ChatContainer doesn't overlap Header */}
            <ChatContainer />
          </div>
        </div>
      </SocketProvider>
    </Provider>
  );
}

export default App;