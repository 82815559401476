import { addSystemMessage, setChatSession, updateMessageById } from '../redux/slices/chatSlice';
import { setGeneratingWithTimeout } from '../redux/slices/generatingSlice';
import { setConnected } from '../redux/slices/websocketSlice'; // Import the action

function setupSocketListeners(socket, store) {
  // Handle WebSocket connection events
  socket.on('connect', () => {
    console.log('Connected to WebSocket server:', socket.id);
    store.dispatch(setConnected(true)); // Update Redux state to connected
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from WebSocket server');
    store.dispatch(setConnected(false)); // Update Redux state to disconnected
  });

  socket.on('error', (error) => {
    console.error('WebSocket error:', error);
    store.dispatch(setConnected(false)); // Handle errors by marking as disconnected
  });

  // Listen for system messages
  socket.on('addSystemMessage', (messagePayload) => {
    console.log('Adding system message:', messagePayload);
    store.dispatch(addSystemMessage(messagePayload));
  });

  // Listen for session data
  socket.on('sessionData', (session) => {
    console.log('Received session data:', session);
    store.dispatch(setChatSession(session));
  });

  // General-purpose listener for updating a message by ID
  socket.on('updateMessage', ({ messageId, updates }) => {
    console.log(`Updating message with ID: ${messageId}`, updates);
    store.dispatch(updateMessageById({ messageId, updates }));
  });

  // Listen for changeGenerating
  socket.on('changeGenerating', (isGenerating) => {
    console.log(`Change generating state to: ${isGenerating}`);
    store.dispatch(setGeneratingWithTimeout(isGenerating));
  });
}

export default setupSocketListeners;