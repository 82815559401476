import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

const CopyableCard = ({ content, timestamp, model, isUser }) => {
    // Function to handle copying text
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard:', text);
            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    };

    // Helper function to render content array into JSX
    const renderContent = (contentArray) => {
        return contentArray.map((item, index) => {
            if (item.type === 'plainText') {
                // Render plain text
                return item.content.split('\n').map((paragraph, pIndex) => (
                    <React.Fragment key={`${index}-${pIndex}`}>
                        {pIndex > 0 && <br />}
                        <span>{paragraph}</span>
                    </React.Fragment>
                ));
            } else if (item.type === 'copyableText') {
                // Render copyable text
                return (
                    <span
                        key={index}
                        className="text-indigo-600 hover:text-indigo-800 cursor-pointer inline-flex items-center"
                        onClick={() => handleCopy(item.content)}
                        title="Click to copy"
                    >
                        {item.content}
                        <DocumentDuplicateIcon className="w-4 h-4 ml-1 text-indigo-600 hover:text-indigo-800" />
                    </span>
                );
            }
            return null;
        });
    };

    return (
        <div
            className={`max-w-xs md:max-w-md lg:max-w-lg p-3 rounded-lg shadow-md ${
                isUser ? 'self-end bg-blue-100 text-right' : 'self-start bg-gray-100 text-left'
            }`}
        >
            {/* Render the sentence content */}
            <div className="text-sm text-gray-800">
                {renderContent(content)}
            </div>

            {/* Timestamp and Model Info */}
            <div className="text-xs text-gray-500 mt-2">
                {new Date(timestamp).toLocaleTimeString()} (Model: {model})
            </div>
        </div>
    );
};

export default CopyableCard;