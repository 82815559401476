import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeSocket, disconnectSocket } from './socket.js';
import setupSocketListeners from './socketListeners';
import store from '../redux/store';

const SocketContext = createContext(null);

export const SocketProvider = ({ children, url }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketInstance = initializeSocket(url);
    setSocket(socketInstance);

    // Setup listeners after initializing the socket
    setupSocketListeners(socketInstance, store);

    return () => {
      disconnectSocket();
    };
  }, [url]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};