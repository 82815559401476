import React, { Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Cog8ToothIcon } from '@heroicons/react/24/solid';
import { useSocket } from '../../socket/socketContext.js';

export default function SettingsMenu() {
  const socket = useSocket();

  const handleClearChat = () => {
    if (!socket) {
      console.error('Socket not available');
      return;
    }

    socket.emit('clearChat', null, (response) => {
      console.log('attempting to clear chat');
      if (response?.error) {
        console.error('Failed to clear chat:', response.error);
      } else {
        console.log('Chat cleared successfully:', response?.response);
      }
    });
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="focus:outline-none">
          <Cog8ToothIcon
            className="h-8 w-8 text-indigo-600 hover:text-indigo-800 cursor-pointer mr-8 mt-2"
            aria-hidden="true"
          />
        </MenuButton>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              {({ active }) => (
                <a
                  href="#account"
                  className={`block px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                >
                  Account Settings
                </a>
              )}
            </MenuItem>
            <MenuItem>
              {({ active }) => (
                <button
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  }`}
                  onClick={handleClearChat}
                >
                  Clear Chat
                </button>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}