import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DocumentDuplicateIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const UserTextCard = ({ content, timestamp, model, categorized, credits }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(content)
      .then(() => console.log('Text copied to clipboard:', content))
      .catch((err) => console.error('Failed to copy text:', err));
  };

  const formattedCredits = credits
    ? parseFloat(credits.$numberDecimal || credits).toFixed(4)
    : null;

  const timeString = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }).replace(/^0/, '');

  const toggleInfo = () => {
    setInfoOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col items-end w-full my-2 group pl-12 pr-3">
      <div className="relative inline-block max-w-md">
        <div className="rounded-lg px-4 py-2 bg-[#C2B9F6] text-left">
          <div className="text-sm text-gray-800 whitespace-pre-wrap">{content}</div>
        </div>
      </div>

      <div className="flex flex-wrap items-center space-x-2 text-xs text-gray-500 mt-1 opacity-0 group-hover:opacity-100 transition-opacity">
        <span>
          <strong>Credits:</strong> {formattedCredits || 'N/A'}
        </span>

        <span>{timeString}</span>

        <button onClick={handleCopy} className="inline-flex items-center justify-center">
          <DocumentDuplicateIcon className="h-4 w-4 hover:text-gray-700" />
        </button>

        <button onClick={toggleInfo} className="inline-flex items-center justify-center">
          <InformationCircleIcon className="h-4 w-4 hover:text-gray-700" />
        </button>
      </div>

      <AnimatePresence>
        {infoOpen && (
          <motion.div
            className="w-full mt-2 bg-gray-100 p-3 rounded-lg shadow-md"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="text-xs text-gray-600 space-y-1">
              <div>
                <strong>Follow-Up:</strong> {categorized?.followUp === true ? 'true' : 'N/A'}
              </div>
              <div>
                <strong>Category:</strong> {categorized?.category || 'N/A'}
              </div>
              <div>
                <strong>Objects:</strong>{' '}
                {Array.isArray(categorized?.salesforceObjects) && categorized.salesforceObjects.length > 0
                  ? categorized.salesforceObjects.join(', ')
                  : 'N/A'}
              </div>
              <div>
                <strong>Target:</strong>{' '}
                {categorized?.targetObject && categorized.targetObject !== 'N/A'
                  ? categorized.targetObject
                  : 'N/A'}
              </div>
              <div>
                <strong>Owned:</strong>{' '}
                {categorized?.userOwned !== undefined
                  ? categorized.userOwned.toString()
                  : 'N/A'}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserTextCard;