import React from 'react';

const RecordCard = ({ content, timestamp, model }) => {
    const { attributes, Id, relatedRecords, ...fields } = content;
    const formattedDate = new Date(timestamp).toLocaleString();

    return (
        <div className="w-full">
            <div className="max-w-screen-lg mx-auto mb-8 rounded-lg shadow-md bg-gray-100 border border-gray-300">
                {/* Header Section */}
                <div className="bg-gray-200 text-gray-800 font-semibold p-3 rounded-t-lg">
                    Salesforce Object: {attributes?.type}
                </div>

                {/* Main Record Details */}
                <div className="p-4 bg-white">
                    <div className="text-sm text-gray-600">
                        <strong>ID:</strong> {Id}
                    </div>
                    {Object.entries(fields).map(([key, value]) => (
                        <div key={key} className="text-sm text-gray-600">
                            <strong>{key}:</strong> {value || "N/A"}
                        </div>
                    ))}
                </div>

                {/* Related Records Section */}
                {relatedRecords?.length > 0 && (
                    <div className="bg-gray-100 border-t border-gray-300 p-4">
                        <div className="font-semibold text-gray-800 mb-2">
                            Related Records:
                        </div>
                        {relatedRecords.map((record, index) => (
                            <div
                                key={index}
                                className="mb-4 p-3 bg-white rounded-lg shadow-sm border border-gray-200"
                            >
                                <div className="text-sm text-gray-600">
                                    <strong>Salesforce Object:</strong>{" "}
                                    {record.attributes?.type}
                                </div>
                                {Object.entries(record).map(([key, value]) => {
                                    if (key !== "attributes" && key !== "Id") {
                                        return (
                                            <div key={key} className="text-sm text-gray-600">
                                                <strong>{key}:</strong> {value || "N/A"}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        ))}
                    </div>
                )}

                {/* Footer Section */}
                <div className="text-xs text-gray-500 my-2 pl-2">
                    {formattedDate} (Model: {model})
                </div>
            </div>
        </div>
    );
};

export default RecordCard;