import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

const UserTextCard = ({ content, timestamp, model, categorized, credits }) => {
  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(content)
      .then(() => console.log('Text copied to clipboard:', content))
      .catch((err) => console.error('Failed to copy text:', err));
  };

  // Extract credits value, supporting both Decimal128 and plain numbers
  const formattedCredits = credits
    ? parseFloat(credits.$numberDecimal || credits).toFixed(4)
    : null;

  const timeString = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }).replace(/^0/, '');

  return (
    <div className="flex flex-col items-end w-full my-2 group pl-12 pr-3">
      {/* User message bubble */}
      <div className="relative inline-block max-w-md">
        <div className="rounded-lg px-4 py-2 bg-[#C2B9F6] text-left">
          <div className="text-sm text-gray-800 whitespace-pre-wrap">{content}</div>
        </div>
      </div>

      {/* Metadata */}
      <div className="flex flex-wrap items-center space-x-2 text-xs text-gray-500 mt-1 opacity-0 group-hover:opacity-100 transition-opacity">
        <span><strong>Category:</strong> {categorized?.category || 'N/A'}</span>

        {/* Conditionally render Salesforce Objects */}
        {Array.isArray(categorized?.salesforceObjects) && categorized.salesforceObjects.length > 0 && (
          <span><strong>Objects:</strong> {categorized.salesforceObjects.join(', ')}</span>
        )}

        {/* Conditionally render Target Object */}
        {categorized?.targetObject && categorized.targetObject !== 'N/A' && (
          <span><strong>Target:</strong> {categorized.targetObject}</span>
        )}

        {/* Conditionally render User Owned */}
        {categorized?.userOwned && (
          <span><strong>Owned:</strong> {categorized.userOwned.toString()}</span>
        )}

        {/* Conditionally render Credits */}
        {formattedCredits && (
          <span><strong>Credits:</strong> {formattedCredits}</span>
        )}

        <span>{timeString}</span>

        <button onClick={handleCopy} className="inline-flex items-center justify-center">
          <DocumentDuplicateIcon className="h-4 w-4 hover:text-gray-700" />
        </button>
      </div>
    </div>
  );
};

export default UserTextCard;