import React from 'react';
import { useSelector } from 'react-redux'; // Import the useSelector hook
import SettingsMenu from './SettingsMenu';
import ModelSelector from './ModelSelector'; // Import the ModelSelector component

const Header = () => {
  const isConnected = useSelector((state) => state.websocket.isConnected); // Access WebSocket status from Redux

  return (
    <header className="fixed top-0 z-20 bg-[#F3F3F3] w-full">
      <div className="flex items-center justify-between p-2 mx-auto max-w-[700px]">
        {/* Logo */}
        <img
          src="/assets/Chat_Logo_01.png"
          alt="Tarn Chat Logo"
          className="h-7 ml-2"
        />

        {/* WebSocket Status */}
        <div className="text-sm text-gray-500">
          {isConnected ? 'Connected' : 'Not Connected'}
        </div>

        {/* Right Side: Model Selector and Settings Menu */}
        <div className="flex items-center space-x-4">
          {/*<ModelSelector />*/}
          <SettingsMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;