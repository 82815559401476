import { io } from 'socket.io-client';

let socket = null;

export const initializeSocket = (url) => {
  if (!socket) {
    socket = io(url, {
      withCredentials: true, // Ensure cookies are sent with the WebSocket connection
    });

    socket.on('connect', () => console.log('Connected to WebSocket server:', socket.id));
    socket.on('disconnect', () => console.log('Disconnected from WebSocket server'));
  }
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export default socket;