import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RecordCard from './message-components/RecordCard.jsx';
import UserTextCard from './message-components/UserTextCard.jsx';
import SystemTextCard from './message-components/SystemTextCard.jsx';
import CopyableCard from './message-components/CopyableCard.jsx';

const MessageThread = () => {
  const messages = useSelector((state) => state.chat.chatSession.messages);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  return (
    <div className="relative h-full">
      {/* Top gradient overlay */}
      <div className="absolute top-0 left-0 right-0 h-10 bg-gradient-to-b from-gray-100 to-transparent pointer-events-none z-10"></div>

      {/* Message container */}
      <div
        ref={containerRef}
        className="relative flex flex-col p-4 space-y-2 h-full overflow-y-auto"
      >
        {messages.map((msg, index) => {
          if (msg.type === 'record') {
            return (
              <RecordCard
                key={index}
                content={msg.content}
                timestamp={msg.timestamp}
                model={msg.model}
              />
            );
          }

          if (msg.type === 'text') {
            const isUser = msg.sender === 'user';
            if (isUser) {
              return (
                <UserTextCard
                  key={index}
                  content={msg.content}
                  timestamp={msg.timestamp}
                  model={msg.model}
                  categorized={msg.categorized}
                  credits = {msg.credits}
                />
              );
            }
            return (
              <SystemTextCard
                key={index}
                content={msg.content}
                timestamp={msg.timestamp}
                model={msg.model}
                credits={msg.credits}
              />
            );
          }

          if (msg.type === 'copyable') {
            return (
              <CopyableCard
                key={index}
                content={msg.content}
                timestamp={msg.timestamp}
                model={msg.model}
              />
            );
          }

          return (
            <div
              key={index}
              className="text-xs text-red-500 self-start"
            >
              Unknown message type: {msg.type}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MessageThread;