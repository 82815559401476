// recordCardUtils.js

export const formatTimeString = (timestamp) => {
    return new Date(timestamp)
        .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        .replace(/^0/, '');
};

export const formatCredits = (credits) => {
    return credits ? parseFloat(credits.$numberDecimal || credits).toFixed(4) : null;
};

export const getHeroIconName = (chatObjects, salesforceObject) => {
    const chatObject = chatObjects.find((obj) => obj.name === salesforceObject);
    return chatObject?.heroIcon || null;
};

export const handleCopyPlainText = (content) => {
    const plainText = JSON.stringify(content, null, 2);
    navigator.clipboard
        .writeText(plainText)
        .then(() => console.log('Entire content copied:', plainText))
        .catch((err) => console.error('Failed to copy content:', err));
};