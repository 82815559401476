import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { messageVariants } from './message-components/utils/messageThreadUtils.js';
import { mirage } from 'ldrs';
import RecordCard from './message-components/RecordCard.jsx';
import UserTextCard from './message-components/UserTextCard.jsx';
import SystemTextCard from './message-components/SystemTextCard.jsx';

mirage.register();

const MessageThread = () => {
  const messages = useSelector((state) => state.chat.chatSession.messages);
  const isGenerating = useSelector((state) => state.generating.isGenerating);

  const containerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  /**
   * Check if the user is scrolled to (or near) the bottom.
   */
  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // Give a small buffer (10px) to account for minor rounding
    const nearBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setIsAtBottom(nearBottom);
  };

  /**
   * Whenever messages change, if we're at bottom, stay at bottom.
   */
  useEffect(() => {
    if (!containerRef.current) return;
    if (isAtBottom) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages, isAtBottom]);

  /**
   * Attach the scroll listener.
   */
  useEffect(() => {
    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => currentRef.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="relative h-full w-full">
      <div className="absolute top-0 left-0 right-0 h-10 bg-gradient-to-b from-gray-100 to-transparent pointer-events-none z-10"></div>

      <div
        ref={containerRef}
        className="relative flex flex-col p-4 space-y-2 h-full overflow-y-auto"
      >
        <AnimatePresence>
          {messages.map((msg, index) => {
            const commonProps = {
              key: msg.messageId || index,
              custom: index,
              variants: messageVariants,
              initial: 'hidden',
              animate: 'visible',
              exit: 'exit',
            };

            if (msg.type === 'record') {
              return (
                <motion.div {...commonProps}>
                  <RecordCard
                    content={msg.content}
                    timestamp={msg.timestamp}
                    model={msg.model}
                  />
                </motion.div>
              );
            }

            if (msg.type === 'text') {
              const isUser = msg.sender === 'user';
              if (isUser) {
                return (
                  <motion.div {...commonProps}>
                    <UserTextCard
                      content={msg.content}
                      timestamp={msg.timestamp}
                      model={msg.model}
                      categorized={msg.categorized}
                      credits={msg.credits}
                    />
                  </motion.div>
                );
              }
              return (
                <motion.div {...commonProps}>
                  <SystemTextCard
                    content={msg.content}
                    timestamp={msg.timestamp}
                    model={msg.model}
                    credits={msg.credits}
                  />
                </motion.div>
              );
            }

            return (
              <motion.div {...commonProps}>
                <div className="text-xs text-red-500 self-start">
                  Unknown message type: {msg.type}
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>

        {/* Placeholder / Animation Container */}
        {/* Only render (or keep height) if user is at bottom */}
        {isAtBottom && (
          <div
            className={`relative mt-4 transition-all duration-200 ease-in-out ${
              isGenerating ? 'h-20' : 'h-20 opacity-70'
            }`}
          >
            {/* If generating, show image + mirage in the same space */}
            {isGenerating && (
              <>
                <div className="absolute left-0 top-1 flex items-center">
                  <img
                    src="assets/tarn_Icon_Purple.png"
                    alt="Generating Icon"
                    className="h-6 w-6 ml-2"
                  />
                </div>
                <div className="absolute left-1/2 transform -translate-x-1/2 pt-1">
                  <l-mirage size="80" speed="2.5" color="#818CF8"></l-mirage>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageThread;