// websocketSlice.js
import { createSlice } from '@reduxjs/toolkit';

const websocketSlice = createSlice({
    name: 'websocket',
    initialState: {
        isConnected: false,
    },
    reducers: {
        setConnected(state, action) {
            state.isConnected = action.payload;
        },
    },
});

export const { setConnected } = websocketSlice.actions;
export default websocketSlice.reducer;